import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'components/library'
import { CtaBlock } from 'components/common/cta-block'
import { Faqs } from 'components/common/faqs'
import { Hero } from 'components/pages/polymerize-labs/polymerize-labs-hero'
import { LabsFeatures } from 'components/pages/polymerize-labs/labs-features'

const PolymerizeLabs = ({ data: { allFaq: { nodes } } }: any) => {
  return (
    <main className="bg-polymerize-labs-hero-bg-mobile lg:bg-polymerize-lab-hero-bg bg-no-repeat bg-contain w-full -mt-28 sm:-mt-36">
      <Container className="flex flex-col pt-44 sm:pt-60 pb-12 gap-10">
        <Hero />
        <LabsFeatures />
      </Container>

      <Container>
        <Faqs faqs={nodes} />
      </Container>

      <CtaBlock />
    </main>
  )
}

export default PolymerizeLabs

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allFaq(filter: {categories: {elemMatch: {name: {eq: "Website Polymerize Labs"}}}}) {
      nodes {
        id
        question
        answer
      }
    }
  }
`

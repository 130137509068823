import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { PrimaryBtn } from 'components/library'
import { Link } from 'gatsby'
import { TranslationKey } from 'types/translation'

type P = {
  title: TranslationKey
  desc: TranslationKey
  image: any
  link: string
}

export type LabsFeaturesCardProps = P

export const LabsFeaturesCard = ({ desc, title, image, link }: P) => {
  const { t } = useTranslation()

  return (
    <Link to={link} target="_blank" className="flex">
      <div className="flex flex-col gap-4 bg-[#DEE9FD] max-w-md rounded-sm shadow-md transition duration-500 hover:scale-105 hover:shadow">
        <img
          alt={t(title) as string}
          className={"w-full overflow-hidden p-0 flex-none"}
          src={image}
        />

        <div className="flex flex-col gap-4 bg-[#DEE9FD] p-8 flex-grow max-h-fit">
          <p className="text-1xl sm:text-2xl text-[#1753E2] flex-none">
            {t(title) as string}
          </p>
          <p className="text-sm flex-grow text-ellipsis">
            {t(desc) as string}
          </p>

          <PrimaryBtn className="md:text-md sm:max-w-max flex-none">
            {t("common.cta.learn-more")}
          </PrimaryBtn>
        </div>
      </div>
    </Link>
  )
}

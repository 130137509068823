import React from 'react'
import { Container } from 'components/library'
import { LabsFeaturesCard, LabsFeaturesCardProps } from './labs-features-card'
import DataManagemntImage from 'images/polymerize-labs/data-management.svg'
import AiImage from 'images/polymerize-labs/ai.svg'
import DoeImage from 'images/polymerize-labs/doe.svg'
import SecurityImage from 'images/polymerize-labs/security.svg'

const cards: LabsFeaturesCardProps[] = [
  {
    title: 'polymerize-labs-page.features.1.title',
    desc: 'polymerize-labs-page.features.1.description',
    image: DataManagemntImage,
    link: '/products/data-management/'
  },
  {
    title: 'polymerize-labs-page.features.2.title',
    desc: 'polymerize-labs-page.features.2.description',
    image: AiImage,
    link: '/products/ai/'
  },
  {
    title: 'polymerize-labs-page.features.3.title',
    desc: 'polymerize-labs-page.features.3.description',
    image: DoeImage,
    link: '/products/doe/'
  },
  {
    title: 'polymerize-labs-page.features.4.title',
    desc: 'polymerize-labs-page.features.4.description',
    image: SecurityImage,
    link: '/security'
  }
]

export const LabsFeatures = () => {
  return (
    <section id="LabsFeatures" className="flex flex-col gap-6 relative py-0">
      <Container className="flex flex-col gap-8 sm:gap-12 py-20 sm:py-20 items-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          {cards.map((card) => {
            return (
              <LabsFeaturesCard
                desc={card.desc}
                title={card.title}
                image={card.image}
                link={card.link}
              />
            )
          })}
        </div>
      </Container>
    </section>
  )
}

import React from 'react'
import { PrimaryBtn } from 'components/library'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

export const Hero = () => {
  const { t } = useTranslation()

  return (
    <section className="grid grid-cols-1 sm:grid-cols-5 gap-10 items-center">
      <div className="text-white flex flex-col gap-8 sm:gap-12 sm:col-span-5">
        <div className="flex flex-col gap-4">
          <h4 className="text-2xl sm:text-2xl md:text-5xl mb-4">
            {t('polymerize-labs-page.hero.title')}
          </h4>

          <p className="sm:text-lg md:text-2xl">
            <Trans
              i18nKey={'polymerize-labs-page.hero.description1'}
              components={{
                anchor: (
                  <a className="underline decoration-1 underline-offset-4" />
                )
              }}
            />
          </p>

          <p className="sm:text-lg md:text-2xl">
            <Trans
              i18nKey={'polymerize-labs-page.hero.description2'}
              components={{
                anchor: (
                  <a className="underline decoration-1 underline-offset-4" />
                )
              }}
            />
          </p>
        </div>

        <PrimaryBtn
          className="md:text-lg ring-theme-grey sm:max-w-max"
          onClick={() => window.requestADemo()}
        >
          {t('common.cta.demo')}
        </PrimaryBtn>
      </div>
    </section>
  )
}
